import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { Trans } from "@lingui/macro"

import { Title, Box, Text } from "../../../components/Core"

import imgL1Brand1 from "../../../assets/image/png/l1-brand1.png"
import imgL1Brand2 from "../../../assets/image/png/l1-brand2.png"
import imgL1Brand3 from "../../../assets/image/png/l1-brand3.png"
import imgL1Brand4 from "../../../assets/image/png/l1-brand4.png"
import imgL1Brand5 from "../../../assets/image/png/l1-brand5.png"

import dachser from "../../../assets/image/svg/logos/Dachser-logo.svg"
import dt from "../../../assets/image/svg/logos/Deutsche_Telekom-Logo.svg"
import telefonica from "../../../assets/image/svg/logos/Telefónica_Logo.svg"
import ge from "../../../assets/image/svg/logos/General_Electric_logo.svg"
import innoscripta from "../../../assets/image/svg/logos/Innoscripta.svg"
import tup from "../../../assets/image/svg/logos/TuP.png"
import stadtwerke from "../../../assets/image/svg/logos/stadtwerke-karlsruhe-vector-logo.svg"
import luithle from "../../../assets/image/svg/logos/Luithle_Luithle.svg"
import conitas from "../../../assets/image/svg/logos/CONITAS.svg"
import ihk from "../../../assets/image/svg/logos/ihk-logo.svg"
import mwk from "../../../assets/image/svg/logos/mwk.png"
import ifl from "../../../assets/image/svg/logos/ifl_kit.png"
import schuon from "../../../assets/image/svg/logos/logo_schuon.png"
import beo from "../../../assets/image/svg/logos/logo_beo.png"


const Brand = styled(Box)`
  filter: grayscale(20%) opacity(79%);
  transition: all 0.3s ease-out;
  &:hover {
  filter: grayscale(10%) opacity(100%);
  }
  &> img {
    max-height:60px;
  }
`

const Clients = () => (
  <>
    {/* <!-- Clients Brands Area --> */}
    <Box py={"30px"}>
      <Container className="align-items-center">
        <Row className="justify-content-center align-items-center mb-3">
          <Col sm="12" md="12">
            <div
              data-aos="zoom-out"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
              className="text-center"
            >
              <Title>
                <Trans>
                  Diese Logistiker vertrauen bereits auf AMAI
                </Trans>
              </Title>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div
          data-aos="zoom-out"
          data-aos-duration="750"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <Box
            className="d-flex justify-content-center justify-content-lg-center
         align-items-center flex-wrap"
            mx="-32px"
          >
            <Brand className="" py={3} mx={4}>
              <img src={ifl} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={dachser} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={tup} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={beo} alt="" className="img-fluid" />
            </Brand>
            {/* Nach Pressemitteilung
            <Brand className="" py={3} mx={4}>
              <img src={schuon} alt="" className="img-fluid" />
            </Brand> */}
            {/* Land BaWü ist kein Logistiker
            <Brand className="" py={3} mx={4}>
              <img src={mwk} alt="" className="img-fluid" />
            </Brand>*/}
          </Box>
        </div>
      </Container>
    </Box>
  </>
)

export default Clients
