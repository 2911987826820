import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { t, Trans } from "@lingui/macro"

import { Title, Box, Text, Button } from "../../../components/Core"
import { device } from "../../../utils"
import imgCasePerfektesPaket from "./claudio-schwarz-purzlbaum-q8kR_ie6WnI-unsplash.jpg"
import imgCaseHochregal from "./ruchindra-gunasekara-GK8x_XCcDZg-unsplash.jpg"

const CaseCardStyled = styled(Box)`
  width: 100%;
  transition: 0.4s;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  .img-container {
    position: relative;
    z-index: 1;
    object-fit: none;
    padding: 0px;

    img {
      border-radius: 0px;
      max-width: 100%;
    }
  }

  box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);

  &:hover {
    transform: translateY(+2px);
    box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;
  ${CaseCardStyled}:hover & {
    opacity: 1;
    margin-top: 0px;
  }
  button {
    font-size: 16px;
    padding: 0.5rem 1.25rem;
    @media ${device.sm} {
      font-size: 18px;
      padding: 0.75rem 1.5rem;
    }
    @media ${device.lg} {
      padding: 0.85rem 1.75rem;
    }
  }
`

const TextContent = styled(Box)`
  position: relative;
  z-index: 1;
  transition: 0.4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 18px;
  padding-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ${device.sm} {
    padding-top: 33px;
    padding-bottom: 41px;
    padding-left: 38px;
    padding-right: 38px;
  }
`

const PreTitle = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 22px;
`

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  @media ${device.sm} {
    font-size: 34px;
    font-weight: 700;
  }
`

const CaseCard = ({
  isDark = false,
  bg = "white",
  img,
  meta = "",
  title = "",
  children = "",
  link = "/case-studies",
}) => {
  return (
    <CaseCardStyled bg={bg}>
      <Link to={link}>
        <div className="img-container">
          <img src={img} alt="" />
          <BtnContainer>
            <Button color="dark" bg="white"><Trans>Case Study lesen</Trans></Button>
          </BtnContainer>
        </div>
      </Link>
      <TextContent bg={bg}>
        <PreTitle color={isDark ? "lightShade" : "darkShade"}>{meta}</PreTitle>
        <TitleStyled color={isDark ? "light" : "dark"}><Link to={link}>{title}</Link></TitleStyled>
        <Text className="tw-grow" color={isDark ? "lightShade" : "darkShade"}>{children}</Text>
        <Text className="tw-mt-4" color={isDark ? "lightShade" : "darkShade"}><Link to={link}><Trans>Mehr Lesen</Trans>&nbsp; →</Link></Text>
      </TextContent>
    </CaseCardStyled>
  )
}

const CaseStudies = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <Box>
        <Container>
          <Row className="justify-content-center d-flex align-items-stretch">


            <Col
              md="6"
              sm="11"
              className="my-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <CaseCard
                img={imgCasePerfektesPaket}
                meta={t`Case Study > Logistik`}
                title={t`Das Perfekte Paket`}
                link="/blog/pakete-optimal-packen-mit-ki/"
              >
                <Trans>Mit Dr. Thomas + Partner (TUP) GmbH, und den Karlsruher Instituten Fördertechnik und Logistiksysteme und Technologie (KIT) entwickeln wir eine KI, dei den Packprozess effizienter gestaltet.</Trans>
              </CaseCard>
            </Col>


            <Col
              md="6"
              sm="11"
              className="my-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <CaseCard
                img={imgCaseHochregal}
                meta={t`Case Study > Logistik`}
                title={t`Reinforcement Learning für Hochregallager`}
                link="/blog/reinforcement-learning-für-hochregallager/"
              >
                <Trans>Wir zeigen, dass mit Hilfe von Reinforcement Learning (RL) eine Steuerung für Regalbediengeräte gelernt werden kann, die flexibler auf neue Situationen reagiert als regelbasierte Ansätze.</Trans>
              </CaseCard>
            </Col>
          </Row>

          {/*
          TODO ACTIVATE THIS ONCE CASE STUDIES SITE EXISTS
          <Link to="/case-studies">
          <Box className="text-center" pt={[4, null, null, null, 5]}>
            <Button variant="outline" color="light">
              Weitere Case Studies
            </Button>
          </Box>
          </Link>
          */}
        </Container>
      </Box>
    </>
  )
}

export default CaseStudies
