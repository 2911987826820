import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import { rgba } from "polished"
import { Container, Row, Col } from "react-bootstrap"

import { Title, Section, Box } from "../../../components/Core"
import { t, Trans } from "@lingui/macro"

import Accordion from "../../../components/Accordion"





const useCases = [
  {
    question: <Trans>Zolltarifnummer Ermittlung</Trans>,
    answer:
      <Trans>
        Mithilfe einer von uns entwickelten KI können jetzt automatisch die 8- und 11-stellige Zolltarifnummer aus der Artikelbeschreibung eines Produkts abgeleitet werden.
      </Trans>,
  },
  {
    question: <Trans>Bedarfsplanung</Trans>,
    answer:
      <Trans>
        KI-gestützte Nachfrageprognosen mit viel mehr Details und Genauigkeit ermöglichen eine bessere Planung als herkömmliche Ansätze.
      </Trans>,
  },
  {
    question: <Trans>Automatisierter Wareneingang</Trans>,
    answer:
      <Trans>
        Mithilfe der maschinellen Bilderkennung können im Wareneingang Qualität von Neuwaren und Retouren ermittelt werden.
      </Trans>,
  },
  {
    question: <Trans>Automatisierte Lagerung</Trans>,
    answer:
      <Trans>
        Roboter können Waren präzise aufnehmen und platzieren. Heute sind auch nicht rechteckige Objekte und der Griff aus der Kiste möglich.
      </Trans>,
  },
  {
    question: <Trans>Paket Optimierung</Trans>,
    answer:
      <Trans>
        Mit Hilfe von Reinforcement Learning können bessere Lösungen für die Platz-Optimierung einzelner Packstücke gefunden werden. <Link to="/blog/pakete-optimal-packen-mit-ki/">Mehr zum Projekt "Das Perfekte Paket" →</Link>
      </Trans>,
  },
  {
    question: <Trans>Fracht Optimierung</Trans>,
    answer:
      <Trans>
        Leerfahrten auffüllen, Sendungen konsolidieren, verschieden Verkehrsmittel kombinieren. Mit Fracht Optimierung reduziert KI Transportkosten.
      </Trans>,
  },
  {
    question: <Trans>Automatisiertes Verpacken</Trans>,
    answer:
      <Trans>
        KI-Assistenten können bei der Kommissionierung und beim Packen helfen. Der Versandprozess gewinnt deutlich an Geschwindigkeit.
      </Trans>,
  },
  {
    question: <Trans>Optimierung der Routen</Trans>,
    answer:
      <Trans>
        Verkürzung der Gesamtlieferzeiten für ganze Flotten oder hybride LKW-Drohnen-Kooperationen für die Paketzustellung.
      </Trans>,
  },
  {
    question: <Trans>Klassifizierung von Adressen</Trans>,
    answer:
      <Trans>
        Die Identifizierung von B2B-Empfängern gewährleistet die korrekte Anwendung von Tarifen für Kunden und korrekte Rückbuchungen.
      </Trans>,
  },
  {
    question: <Trans>Echtzeit Tracking und Vorhersage</Trans>,
    answer:
      <Trans>
        Zeitreihenprognosen in Echtzeit ermöglichen präzise Liefervorhersagen und erhöhen die Kundenzufriedenheit.
      </Trans>

    /* weitere Use Cases
    {
      question: <Trans>Planung</Trans>,
      answer:
        <Trans>Kurzer Text.</Trans>,
    },
    {
      question: <Trans>Bedarfsprognose</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    }, {
      question: <Trans>Planung der Versorgung</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    }, {
      question: <Trans>Lagerroboter</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    }, {
      question: <Trans>Schadenserkennung/ Visuelle Inspektion</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Prädiktive Wartung</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Selbstfahrende Fahrzeuge</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Lieferdrohnen</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Dynamische Preisgestaltung</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Routenoptimierung/Freight Management</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Automatisierung von manuellen Büroaufgaben</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
    {
      question: <Trans>Chatbot für den Kundenservice</Trans>,
      answer:
        <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</Trans>,
    },
  */
  }
];


const UseCases = () => (
  <>

    <Accordion questionsAnswers={useCases} />

  </>
)

export default UseCases
