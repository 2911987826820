import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import { Title, Button, Section, Box, Text } from "../../components/Core"
import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/SEO"
import Hero from "../../sections/industries/logistics/HeroLogistics"
import { t, Trans } from "@lingui/macro"


import imgLogistics from "../../assets/image/jpeg/industry-logistics.jpg"
import imgProcess from "../../sections/industries/logistics/Header.svg"
import imgAdoption from "../../sections/industries/logistics/AIAdoptionForecast.png"

import UseCases from "../../sections/industries/logistics/LogisticsUseCases"
import CaseStudies from "../../sections/industries/logistics/CaseStudies"
import Clients from "../../sections/industries/logistics/ClientsNarrowBannerLogistics"




const Industry = ({ location }) => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <SEO
          pathname={location.pathname}
          title={t`KI in der Logistik`}
          description={t`Hier stellen wir KI-Projekte vor, die wir in den letzten drei Jahren in der Logistikbranche umgesetzt haben.`}
        />








        <Hero />








        <Section className="my-0 py-3" bg="#f7f7fb">
          <Container>
            <Link to="/industries/">← &nbsp;<Trans>Alle Industrien</Trans></Link>
            {/* 
              CAN ALSO LINK DIRETLY TO CORRESPONDING SECTION IN OVERVIEW #logistics/#telecom/#utilities
              <Link to="/industries/#logistics">← &nbsp;<Trans>Alle Industrien</Trans></Link>
            */}
          </Container>
        </Section>








        <Section id="solutions" bg="#f7f7fb">
          <Container>
            <Row className="justify-content-center">

              <Col className="col-lg-5 offset-lg-1 order-2 mt-4 mt-lg-0">
                {/* sm:tw-mt-4 lg:tw-mt-2 funktioniert nicht, obwohl so in PostLayout.js erfolgreich tailwind.css integiert wird. */}
                <div>
                  <div
                    data-aos="zoom-out"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    {/*ADD interactive Graph-JS once done with prototype https://janosh.dev/blog/gatsby-interactive-plots*/}
                    <Box borderRadius={4} className="position-relative  overflow-hidden tw-mt-5 tw-mt-lg-0">
                      <img src={imgAdoption} alt="" className="w-100 h-100" style="filter: brightness(1.2) contrast(1.01) grayscale(0.5); grayscale(0.5); object-fit: contain; object-position: bottom;" />
                      <div className="tw-text-center">
                        <Text variant="small">
                          <span className="tw-text-amaidarkgrey">
                            <Trans>Die jährliche Mitgliederbefragung des Logistikverbands untersucht den Einsatz einzelner Technologien. Viele planen in den nächsten Monaten erste KI Projekte in ihren Lagerhäusern.</Trans> <a href="https://www.mhi.org/publications/report" className="tw-text-amaidarkgrey">(Quelle: MHI, 2021)</a>
                          </span>
                        </Text>
                        <Text variant="small" className="tw-mt-3">
                          <Link to="/blog/ki-adoption-in-der-logistik/"><Trans>Mehr zu den Statistiken in unserem Blogbeitrag</Trans>&nbsp; →</Link>
                        </Text>
                      </div>
                    </Box>
                  </div>
                </div>
              </Col>

              {/* TODO Add better spacing between these on mobile */}

              <Col
                md="12"
                lg="6"
                className="order-lg-1 my-2"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
                  <Title color="dark">
                    <Trans>Jetzt Chancen ergreifen</Trans>
                  </Title>
                  <Text color="darkShade"><Trans>
                    Wer die Einführung von Künstlicher Intelligenz aufschiebt, wird bis zum Ende des Jahrzehnts 23% des operativen Cashflows verlieren - hauptsächlich an KI-gestützte Wettbewerber. Zu dieser Prognose kommt das McKinsey Global Institute.
                  </Trans></Text>
                  <Text color="darkShade" className="tw-mt-3"><Trans>
                    Die Logistik Branche reagiert! Während im Jahr 2020 nur 17% der vom Logistikverband MHI Befragten angaben, künstliche Intelligenz in ihren Lagern einzusetzen, planen mehr als 60% den Einsatz von KI innerhalb der nächsten 3 bis 5 Jahre.
                  </Trans></Text>
                  <Text className="tw-my-5 tw-py-5 !tw-text-2xl"><Trans>
                    <strong>Jetzt handelnde Unternehmen sichern ihre zukünftige Wettbewerbsfähigkeit</strong>.
                  </Trans></Text>

                  {/*
                    Companies that postpone adopting artificial intelligence will lose 23% of their operating cash flow by the end of the decade - mainly to AI-supported competitors. That is the conclusion of the McKinsey Global Institute. And the logistics industry is responding!While only 17% of those surveyed by the logistics association MHI said they would use artificial intelligence in their warehouses in 2020, more than 60% plan to use AI within the next 3 to 5 years. 
                    
                    → Read more in our blog post
                  */}
                </div>
              </Col>
            </Row>
          </Container>
        </Section>








        <Section className="d-block d-sm-block pb-3 pb-md-5">
          <Container>
            <Row className="justify-content-center align-items-center mb-5 mt-3">
              <Col sm="12" md="9">
                <div
                  data-aos="zoom-out"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Title color="dark">
                    <Trans>KI in der Logistik</Trans>
                  </Title>
                  <Text><Trans>
                    Transport und Intralogistik bieten ein enormes wirtschaftliches Potenzial für den Einsatz von KI. Durch den Einsatz von Machine-Learning-Technologien können Unternehmen Zeit und Geld sparen, und da viele Prozesse bereits digitalisiert sind, lassen sich Anwendungsfälle heute schnell umsetzen. <br />

                    Rund um den Globus wird an Lösungen geforscht, um einzelne Teile der Lieferkette intelligent zu machen. Diese haben wir in unserer Vision KI in der Logistik beispielhaft skizziert:
                  </Trans></Text>
                </div>
              </Col>
            </Row>
          </Container>


          <Container fluid className="mt-3 mb-5 pt-md-4">

            <Row className="justify-content-center align-items-center">
              <div
                data-aos="zoom-out"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
                className="text-center w-100"
                style="max-width: 1140px;">

                <object data={imgProcess} type="image/svg+xml" className="my-2">
                  <img src={imgProcess} alt="AI in Logistics - Vision 2030" />
                </object>

                <Text variant="small" className="d-block d-sm-none"><Trans>Zoom In</Trans></Text>
              </div>
            </Row>
          </Container>

        </Section>


        <Section className="mt-0 pt-0">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col sm="12" md="9">


                <Row data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500" className="mb-5">

                  <Col>
                    <Text><Trans>
                      Im Folgenden finden Sie exemplarische KI-Anwendungsfälle entlang der Lieferkette erläutert. Diese Sammlung von wirtschaftlich erprobten Lösungen verspricht einen konkreten Mehrwert für die Unternehmen der Branche. Klicken Sie auf die einzelnen Elemente, um mehr über Ihre Einsatzmöglichkeiten zu erfahren.
                    </Trans></Text>
                  </Col>

                </Row>


                <Row
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                  className="justify-content-center align-items-center mb-4"
                >

                  <UseCases />

                </Row>


                <Row
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                  className="justify-content-center align-items-center mb-4"
                >

                  <Col lg="8" className="mb-3">
                    <Text variant="small" color="darkShade">
                      <Trans>
                        Die Logistik bietet eine Vielzahl weiterer Use Cases für KI Integrierungen. Wir haben <strong>100+ Anwendungsfälle</strong> aus verschiedenen Industrien gesammelt.
                      </Trans>
                    </Text>
                  </Col>
                  <Col lg="4">
                    <Text variant="small" textAlign="right">
                      <Link to="/applications/"><Trans>Weitere Use Cases</Trans>&nbsp; →</Link>
                    </Text>
                  </Col>
                </Row>


              </Col>
            </Row>
          </Container>
        </Section >


        <Section bg="#206282">
          <Container>
            <Row>
              <Col md="12"
                lg="9"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >

                <Title color="light">
                  <Trans>Referenzprojekte</Trans>
                </Title>
                {/*<Text color="lightShade">
                  <Trans>Lorem ipsum</Trans>
                </Text>*/}
              </Col>
            </Row>
          </Container>

          <CaseStudies />

        </Section>








        <Section bg="#F7F7FB" className="py-3">
          <Clients />
        </Section>









        {/*
        <Section bg="#F7F7FB">
          <Container>
            <Row>
              <Col md="12" lg="12"
                data-aos="fade-right" data-aos-duration="750" data-aos-once="true"
              >

                <Title color="dark">
                  <Trans>Wie wir Kunden in der Logistikbranche helfen</Trans>
                </Title>
                <Text color="darkShade">
                  <Trans>Lorem ipsum</Trans>

                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        */}








        <Section id="solutions">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg="5" className="offset-lg-1 order-2 py-5 my-5 my-lg-0">
                <div>
                  <div
                    data-aos="zoom-out"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    {/*ADD ONCE SUB PAGE IS COMPLETED <Link to="/industries/logistics">
                  </Link>*/}
                    <Box borderRadius={4} className="position-relative  overflow-hidden" height="600px" maxHeight="60vh">
                      <img src={imgLogistics} alt="" className="w-100 h-100" style="filter: brightness(1.2) contrast(1.01) grayscale(0.5); grayscale(0.5); object-fit: cover; object-position: bottom;" />
                    </Box>
                  </div>
                </div>
              </Col>
              <Col
                md="10"
                lg="6"
                className="order-lg-1 my-5 my-lg-0"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>

                  <Title color="dark">
                    <Trans>Nutzen Sie bereits KI in Ihrem Unternehmen?</Trans>
                  </Title>
                  <Text color="darkShade"><Trans>
                    Mit unseren maßgeschneiderten Machine Learning Lösungen verschaffen Ihrem Logistikbetrieb den entscheidenden Wettbewerbsvorteil. In einer kostenlosen Erstberatung sondieren wir Ihr KI-Potential.
                  </Trans></Text>
                  <div className="mt-5">
                    <Link to="/contact/?utm_source=logistics">
                      <Button className="text-wrap mw-100 py-4"><Trans>Sprechen Sie uns an!</Trans></Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>













      </PageWrapper>
    </>
  )
}
export default Industry
